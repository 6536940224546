import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios'
import moment from "moment";

Vue.use(Vuex);

axios.defaults.baseURL = 'https://backgroundprocess.absolutecabs.co.ke/api/absolute'
// axios.defaults.baseURL = 'https://abcbackend.absolutecabs.co.ke/api/absolute'
// axios.defaults.withCredentials = true;

export default new Vuex.Store({
  state: {
  	origin: {lat:-1.2766283, lng:36.7870517},
  	destination: {lat:-1.2766283, lng:36.7870517},
  	booking: null,
  	waypoints: [],
  	rate:0,
  	pickup:"",
  	drop:"",
  },
  getters: {},
  mutations: {
		getBooking(state, data) {
			if(data.statusCode === parseInt(200)) {

				console.log(data.booking)

				state.booking = data.booking
				state.rate = data.rate

				// state.origin = {lat:parseFloat(state.booking.fromlat), lng:parseFloat(state.booking.fromlong)}
				// state.destination = {lat:parseFloat(state.booking.tolat), lng:parseFloat(state.booking.tolong)}

				// if(state.booking.travelstop1 !== null && state.booking.travelstop1 !== "null") {
				// 	state.waypoints.push({
		  //                   location: {lat:parseFloat(state.booking.stop1lat), lng:parseFloat(state.booking.stop1long)},
		  //                   stopover: true
		  //                 });
				// }
				// if(state.booking.travelstop2 !== null && state.booking.travelstop2 !== "null") {
				// 	state.waypoints.push({
		  //                   location: {lat:parseFloat(state.booking.stop2lat), lng:parseFloat(state.booking.stop2long)},
		  //                   stopover: true
		  //                 });
				// }
			}
		},
  },
  actions: {
  	getBooking ({ commit },res) {
		return axios
		.get(`/customer/e-voucher/${res.refno}`,{ headers: { "Access-Control-Allow-Origin": "*" } })
		.then(({ data }) => {
			commit('getBooking', data)
		})
	},
  },
  modules: {},
});

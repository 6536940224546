<template>
  <div class="wrapper">
    <v-row>
      <div id="up-next" class="d-flex mb-4 mt-2 mx-5">
        <a :href="`/customer/e-voucher/${$route.params.id}`" class="text-decoration-none text-body-1">
          <span class="text-h6 text--black">←&nbsp;</span><span>E-voucher</span></a>
      </div>
    </v-row>
    <div class="contain" id="printReceipt" v-if="booking !== null">
      <div class="row my-1 mx-1"><p>Thank you for riding with us!</p></div>
      <div class="row">
        <table align="center" border="0" cellpadding="0" cellspacing="0"
               width="550" bgcolor="white">
            <tbody>
                <tr>
                    <td colspan="2" align="center">
                        <table align="center" border="0" cellpadding="0"
                               cellspacing="0" class="col-550" width="550">
                            <tbody>
                                <tr>
                                    <td colspan="2" align="center" style="background-color: #fff;
                                               height: 50px;">
       
                                        <a href="https://absolutecabs.co.ke/" style="text-decoration: none;text-align:center !important;width:100% !important">
                                            <p style="color:white;text-align:center !important">
                                                <img src="@/assets/absolutelogotext.jpg" style="width:25% !important;text-align:center !important"/>
                                            </p>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr style="height: 110px;">
                    <td colspan="2" align="center" style="border: none;
                               border-bottom: 1px solid #f5f6f7; 
                               padding-right: 20px;padding-left:20px;padding-top:20px">
                        
                        <h2 style="text-align: right;
                                   align-items: center;font-size: .8rem !important;width:100% !important;font-weight:normal !important;line-height:2;letter-spacing: 1px;">
                            <b>Ref No:</b> {{ booking.ref_no }}<br>
                            <b>Booked On:</b> {{ moment(booking.created_at).format('YYYY-MM-DD H:m:s') }}<br>
                            <b>Expected Pickup Time:</b> {{ booking.pickup_date+" "+booking.pickup_time }}
                       </h2>
       
                    </td>
                </tr>
       
                <tr>
                    <td colspan="2" style="border: none; 
                               border-bottom: 1px solid #f5f6f7;
                               background-color: #f6f8f9;">
                       
                        <p class="data"
                           style="text-align: justify-all;padding: 0px;
                                  align-items: center; 
                                  font-size: 15px;">

                            Booking Details
                        </p>
                    </td>
                </tr>
                <tr class="details">
                    <td style="border: none; 
                               background-color: #fff;">
                            Organisation
                    </td>
                    <td style="border: none; 
                               background-color: #fff;">
                            <span v-if="booking.organisation !== null">{{ booking.organisation.name }}</span>
                            <span v-else>Private</span>
                    </td>
                </tr>
                <tr class="details">
                    <td style="border: none; 
                               background-color: #fff;">
                            Passenger
                    </td>
                    <td style="border: none; 
                               background-color: #fff;">
                        {{ booking.passenger_name }}
                    </td>
                </tr>
                <tr class="details">
                    <td style="border: none; 
                               background-color: #fff;">
                            Pickup Date and Time
                    </td>
                    <td style="border: none; 
                               background-color: #fff;">
                        <span v-if="booking.driver_pickup_date !== null">{{ booking.driver_pickup_date+" "+booking.driver_pickup_time }}</span>
                        <span v-else>N/A</span>
                    </td>
                </tr>
                <tr class="details">
                    <td style="border: none; 
                               background-color: #fff;">
                            Drop Date and Time
                    </td>
                    <td style="border: none; 
                               background-color: #fff;">
                        <span v-if="booking.driver_drop_date !== null">{{ booking.driver_drop_date+" "+booking.driver_drop_time }}</span>
                        <span v-else>N/A</span>
                    </td>
                </tr>
                <tr class="details">
                    <td style="border: none; 
                               background-color: #fff;">
                            Pickup Location
                    </td>
                    <td style="border: none; 
                               background-color: #fff;line-height:1.4">
                        {{ booking.travelfrom }}<br>
                        <span style="color:#777" v-if="booking.driver_arrival_date !== null">Arrival Time: 
                          {{ booking.driver_arrival_date+" "+booking.driver_arrival_time }}
                        </span>
                        <span style="color:#777" v-else>Arrival Time : N/A</span><br>
                        <span style="color:#777" v-if="booking.driver_arrival_date !== null && booking.driver_pickup_date !== null">Pickup Time Delay: 
                          {{ moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes() | currency('') }} min
                        </span>
                        <span style="color:#777" v-else>Pickup Time Delay: 0 min</span>
                    </td>
                </tr>
                <tr class="details" v-if="booking.travelstop1 !== null || booking.travelstop2 !== null">
                    <td style="border: none; 
                               background-color: #fff;">
                            Stops
                    </td>
                    <td style="border: none; 
                               background-color: #fff;line-height:1.4">
                        <span v-if="booking.travelstop1 !== null">. {{ booking.travelstop1 }}</span><br>
                        <span v-if="booking.travelstop2 !== null">. {{ booking.travelstop2 }}</span>
                    </td>
                </tr>
                <tr class="details">
                    <td style="border: none; 
                               background-color: #fff;">
                            Drop Location
                    </td>
                    <td style="border: none; 
                               background-color: #fff;line-height:1.4">
                        {{ booking.travelto }}
                    </td>
                </tr>

                <tr class="divide"><td style="border-bottom: 1px solid #f5f6f7 !important;" colspan="2"></td></tr>
                <tr>
                    <td colspan="2" style="border: none; 
                               border-bottom: 1px solid #f5f6f7;
                               background-color: #f6f8f9;">
                       
                        <p class="data"
                           style="text-align: justify-all;padding: 0px;
                                  align-items: center; 
                                  font-size: 15px;">

                            Payment Details
                        </p>
                    </td>
                </tr>
                <tr class="details">
                    <td style="border: none; 
                               background-color: #fff;">
                            Payment Method
                    </td>
                    <td style="border: none; 
                               background-color: #fff;">
                        INVOICE
                    </td>
                </tr>
                <tr class="details">
                    <td style="border: none; 
                               background-color: #fff;">
                            Mileage
                    </td>
                    <td style="border: none; 
                               background-color: #fff;">
                        {{ booking.distance }} Km
                    </td>
                </tr>
                <tr class="details">
                    <td style="border: none; 
                               background-color: #fff;">
                            Time
                    </td>
                    <td style="border: none; 
                               background-color: #fff;">
                        <span v-if="booking.driver_pickup_date !== null && booking.driver_dropoff_date !== null">
                          {{ moment.duration(moment(booking.driver_drop_date+" "+booking.driver_drop_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes() | currency('') }} Min
                        </span>
                        <span v-else>0 Min</span>
                    </td>
                </tr>
                <tr class="details">
                    <td style="border: none; 
                               background-color: #fff;">
                            Journey Fare
                    </td>
                    <td style="border: none; 
                               background-color: #fff;">
                               <div v-if="parseInt(booking.movement_id) == 0">
                                 <div v-if="booking.organisationrate.length > 0">
                                   <span v-if="parseInt(booking.organisationrate[0].base_rate) === 0"> {{ Math.round((booking.distance)*(booking.organisationrate.length === 0 ? 0 : booking.organisationrate[0].rate)) | currency('') }}</span>
                                  <span v-else-if="parseInt(booking.organisationrate[0].base_rate) > 0 && parseInt(((booking.distance)*(booking.organisationrate.length === 0 ? 0 : booking.organisationrate[0].rate))) > parseInt(booking.organisationrate[0].base_rate)"> {{ Math.round((booking.distance)*(booking.organisationrate.length === 0 ? 0 : booking.organisationrate[0].rate)) | currency('') }}</span>
                                  <span v-else> {{ booking.organisationrate[0].base_rate | currency('') }}</span>
                                </div>
                                <div v-else>0.00</div>
                              </div>
                              <div v-else>{{ booking.amount | currency('') }}</div>
                    </td>
                </tr>
                <tr class="details">
                    <td style="border: none; 
                               background-color: #fff;">
                            Expressway Fee
                    </td>
                    <td style="border: none; 
                               background-color: #fff;">
                            {{ booking.expressway.length > 0 ? Math.round(booking.expressway.reduce((acc1, value) => { return  acc1 += value.rate.rate}, 0)) : 0 | currency(' ') }}
                    </td>
                </tr>
                <tr class="details">
                    <td style="border: none; 
                               background-color: #fff;">
                            Waiting Time Fee
                    </td>
                    <td style="border: none; 
                               background-color: #fff;">
                        <div v-if="booking.waitingfee !== null && booking.driver_pickup_date !== null && booking.driver_arrival_date !== null">
                          {{
                              parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(booking.waitingfee.minutes) ? (Math.round((parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(booking.waitingfee.minutes)) * parseFloat(booking.waitingfee.rate)) + booking.waiting.reduce((acc1, value) => { 
                                                            let total = 0;
                                                            if(value.starttime !== null && value.endtime !== null) {
                                                              
                                                              if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(value.rate.minutes)) {
                                                                total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(value.rate.minutes)) * parseFloat(value.rate.rate));
                                                              }

                                                            }
                                                            return  acc1 += total
                                                          }, 0)) : 0 | currency(' ')

                          }}
                        </div>
                        <div v-else>0.00</div>
                    </td>
                </tr>
                <tr class="details">
                    <td style="border: none; 
                               background-color: #fff;">
                            VAT
                    </td>
                    <td style="border: none; 
                               background-color: #fff;">
                        <div v-if="parseInt(booking.movement_id) == 0">
                          <div v-if="booking.organisationrate.length > 0">
                            <span v-if="parseInt(booking.organisationrate[0].base_rate) === 0"> {{ booking.customer.organisation.vat !== '0' ? (((Math.round((booking.distance)*(booking.organisationrate.length === 0 ? 0 : booking.organisationrate[0].rate)) + (parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(booking.waitingfee.minutes) ? (Math.round((parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(booking.waitingfee.minutes)) * parseFloat(booking.waitingfee.rate)) + booking.waiting.reduce((acc1, value) => { 
                                                              let total = 0;
                                                              if(value.starttime !== null && value.endtime !== null) {
                                                                
                                                                if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(value.rate.minutes)) {
                                                                  total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(value.rate.minutes)) * parseFloat(value.rate.rate));
                                                                }

                                                              }
                                                              return  acc1 += total
                                                            }, 0)) : 0))*rate)/100) : 0 | currency('') }}</span>
                                  <span v-else-if="parseInt(booking.organisationrate[0].base_rate) > 0 && parseInt(((booking.distance)*(booking.organisationrate.length === 0 ? 0 : booking.organisationrate[0].rate))) > parseInt(booking.organisationrate[0].base_rate)"> {{ booking.customer.organisation.vat !== '0' ? (((Math.round((booking.distance)*(booking.organisationrate.length === 0 ? 0 : booking.organisationrate[0].rate)) + (parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(booking.waitingfee.minutes) ? (Math.round((parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(booking.waitingfee.minutes)) * parseFloat(booking.waitingfee.rate)) + booking.waiting.reduce((acc1, value) => { 
                                                              let total = 0;
                                                              if(value.starttime !== null && value.endtime !== null) {
                                                                
                                                                if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(value.rate.minutes)) {
                                                                  total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(value.rate.minutes)) * parseFloat(value.rate.rate));
                                                                }

                                                              }
                                                              return  acc1 += total
                                                            }, 0)) : 0))*rate)/100) : 0 | currency('') }}</span>
                                  <span v-else> {{ booking.customer.organisation.vat !== '0' ? ((((booking.organisationrate[0].base_rate) + (parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(booking.waitingfee.minutes) ? (Math.round((parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(booking.waitingfee.minutes)) * parseFloat(booking.waitingfee.rate)) + booking.waiting.reduce((acc1, value) => { 
                                                              let total = 0;
                                                              if(value.starttime !== null && value.endtime !== null) {
                                                                
                                                                if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(value.rate.minutes)) {
                                                                  total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(value.rate.minutes)) * parseFloat(value.rate.rate));
                                                                }

                                                              }
                                                              return  acc1 += total
                                                            }, 0)) : 0))*rate)/100) : 0 | currency('') }}</span>
                          </div>
                          <div v-else>0.00</div>
                        </div>
                        <div v-else>
                          {{ booking.customer.organisation.vat !== '0' ? ((((booking.amount) + (parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(booking.waitingfee.minutes) ? (Math.round((parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(booking.waitingfee.minutes)) * parseFloat(booking.waitingfee.rate)) + booking.waiting.reduce((acc1, value) => { 
                                                              let total = 0;
                                                              if(value.starttime !== null && value.endtime !== null) {
                                                                
                                                                if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(value.rate.minutes)) {
                                                                  total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(value.rate.minutes)) * parseFloat(value.rate.rate));
                                                                }

                                                              }
                                                              return  acc1 += total
                                                            }, 0)) : 0))*rate)/100) : 0 | currency('') }}
                        </div>
                    </td>
                </tr>
                <tr class="details">
                    <td style="border: none; 
                               background-color: #fff;">
                            Total
                    </td>
                    <td style="border: none; 
                               background-color: #fff;font-weight:bold">
                        <div v-if="parseInt(booking.movement_id) == 0">
                          <div v-if="booking.organisationrate.length > 0">
                            <span v-if="parseInt(booking.organisationrate[0].base_rate) === 0"> {{ ( (booking.expressway.reduce((acc1, value) => { return  acc1 += value.rate.rate}, 0)) + (parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(booking.waitingfee.minutes) ? (Math.round((parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(booking.waitingfee.minutes)) * parseFloat(booking.waitingfee.rate)) + booking.waiting.reduce((acc1, value) => { 
                                                              let total = 0;
                                                              if(value.starttime !== null && value.endtime !== null) {
                                                                
                                                                if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(value.rate.minutes)) {
                                                                  total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(value.rate.minutes)) * parseFloat(value.rate.rate));
                                                                }

                                                              }
                                                              return  acc1 += total
                                                            }, 0)) : 0) + Math.round((booking.distance)*(booking.organisationrate.length === 0 ? 0 : booking.organisationrate[0].rate)) + (booking.customer.organisation.vat !== '0' ? (((Math.round((booking.distance)*(booking.organisationrate.length === 0 ? 0 : booking.organisationrate[0].rate)) + (parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(booking.waitingfee.minutes) ? (Math.round((parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(booking.waitingfee.minutes)) * parseFloat(booking.waitingfee.rate)) + booking.waiting.reduce((acc1, value) => { 
                                                              let total = 0;
                                                              if(value.starttime !== null && value.endtime !== null) {
                                                                
                                                                if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(value.rate.minutes)) {
                                                                  total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(value.rate.minutes)) * parseFloat(value.rate.rate));
                                                                }

                                                              }
                                                              return  acc1 += total
                                                            }, 0)) : 0))*rate)/100) : 0)) | currency('') }}</span>

                                  <span v-else-if="parseInt(booking.organisationrate[0].base_rate) > 0 && parseInt(((booking.distance)*(booking.organisationrate.length === 0 ? 0 : booking.organisationrate[0].rate))) > parseInt(booking.organisationrate[0].base_rate)"> {{ ( (booking.expressway.reduce((acc1, value) => { return  acc1 += value.rate.rate}, 0)) + (parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(booking.waitingfee.minutes) ? (Math.round((parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(booking.waitingfee.minutes)) * parseFloat(booking.waitingfee.rate)) + booking.waiting.reduce((acc1, value) => { 
                                                              let total = 0;
                                                              if(value.starttime !== null && value.endtime !== null) {
                                                                
                                                                if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(value.rate.minutes)) {
                                                                  total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(value.rate.minutes)) * parseFloat(value.rate.rate));
                                                                }

                                                              }
                                                              return  acc1 += total
                                                            }, 0)) : 0) + Math.round((booking.distance)*(booking.organisationrate.length === 0 ? 0 : booking.organisationrate[0].rate)) + (booking.customer.organisation.vat !== '0' ? (((Math.round((booking.distance)*(booking.organisationrate.length === 0 ? 0 : booking.organisationrate[0].rate)) + (parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(booking.waitingfee.minutes) ? (Math.round((parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(booking.waitingfee.minutes)) * parseFloat(booking.waitingfee.rate)) + booking.waiting.reduce((acc1, value) => { 
                                                              let total = 0;
                                                              if(value.starttime !== null && value.endtime !== null) {
                                                                
                                                                if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(value.rate.minutes)) {
                                                                  total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(value.rate.minutes)) * parseFloat(value.rate.rate));
                                                                }

                                                              }
                                                              return  acc1 += total
                                                            }, 0)) : 0))*rate)/100) : 0)) | currency('') }}</span>

                                  <span v-else> {{ ( (booking.expressway.reduce((acc1, value) => { return  acc1 += value.rate.rate}, 0)) + (parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(booking.waitingfee.minutes) ? (Math.round((parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(booking.waitingfee.minutes)) * parseFloat(booking.waitingfee.rate)) + booking.waiting.reduce((acc1, value) => { 
                                                              let total = 0;
                                                              if(value.starttime !== null && value.endtime !== null) {
                                                                
                                                                if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(value.rate.minutes)) {
                                                                  total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(value.rate.minutes)) * parseFloat(value.rate.rate));
                                                                }

                                                              }
                                                              return  acc1 += total
                                                            }, 0)) : 0) + (booking.organisationrate[0].base_rate) + (booking.customer.organisation.vat !== '0' ? ((((booking.organisationrate[0].base_rate) + (parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(booking.waitingfee.minutes) ? (Math.round((parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(booking.waitingfee.minutes)) * parseFloat(booking.waitingfee.rate)) + booking.waiting.reduce((acc1, value) => { 
                                                              let total = 0;
                                                              if(value.starttime !== null && value.endtime !== null) {
                                                                
                                                                if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(value.rate.minutes)) {
                                                                  total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(value.rate.minutes)) * parseFloat(value.rate.rate));
                                                                }

                                                              }
                                                              return  acc1 += total
                                                            }, 0)) : 0))*rate)/100) : 0)) | currency('') }}</span>
                          </div>
                          <div v-else>0.00</div>
                        </div>
                        <div v-else>
                          {{ ( (booking.expressway.reduce((acc1, value) => { return  acc1 += value.rate.rate}, 0)) + (parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(booking.waitingfee.minutes) ? (Math.round((parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(booking.waitingfee.minutes)) * parseFloat(booking.waitingfee.rate)) + booking.waiting.reduce((acc1, value) => { 
                                                              let total = 0;
                                                              if(value.starttime !== null && value.endtime !== null) {
                                                                
                                                                if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(value.rate.minutes)) {
                                                                  total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(value.rate.minutes)) * parseFloat(value.rate.rate));
                                                                }

                                                              }
                                                              return  acc1 += total
                                                            }, 0)) : 0) + (booking.amount) + (booking.customer.organisation.vat !== '0' ? ((((booking.amount) + (parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(booking.waitingfee.minutes) ? (Math.round((parseFloat(moment.duration(moment(booking.driver_pickup_date+" "+booking.driver_pickup_time,'YYYY-MM-DD HH:mm:ss').diff(moment(booking.driver_arrival_date+" "+booking.driver_arrival_time,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(booking.waitingfee.minutes)) * parseFloat(booking.waitingfee.rate)) + booking.waiting.reduce((acc1, value) => { 
                                                              let total = 0;
                                                              if(value.starttime !== null && value.endtime !== null) {
                                                                
                                                                if(parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) > parseInt(value.rate.minutes)) {
                                                                  total = Math.round((parseFloat(moment.duration(moment(value.endtime,'YYYY-MM-DD HH:mm:ss').diff(moment(value.starttime,'YYYY-MM-DD HH:mm:ss'))).asMinutes()) - parseInt(value.rate.minutes)) * parseFloat(value.rate.rate));
                                                                }

                                                              }
                                                              return  acc1 += total
                                                            }, 0)) : 0))*rate)/100) : 0)) | currency('') }}
                        </div>
                    </td>
                </tr>

                <tr class="divide">
                  <td style="border:none !important;"></td>
                  <td style="border:none !important;display:flex;justify-content:flex-end;width:100%;text-align:right;float:right">
                    <p style="height:2px;border-bottom: 1px solid #f6f8f9 !important;border-top: 1px solid #f6f8f9 !important;width:30% !important"></p>
                  </td>
                </tr>

                <tr class="details">
                    <td style="border: none; 
                               background-color: #fff;">
                            
                    </td>
                    <td style="border: none; 
                               background-color: #fff;">
                        <img :src="booking.signature" class="signature" v-if="booking.signature !== null" style="width:50%"/>
                        <img :src="booking.driver_signature" class="signature" v-else style="width:50%"/>
                    </td>
                </tr>

              <tr>
                <td colspan="2" style="font-family:'Open Sans', Arial, sans-serif;
                           font-size:11px; line-height:18px; 
                           color:#999999;" 
                    valign="top"
                    align="center">
                    <br>
                    For more information call
                  <a href="tel:+254721400422"
                   style="color:#999999; 
                          text-decoration:underline;">+254 721 400422</a> 
                          | <a href="tel:+254728606654" 
                          style="color:#999999; text-decoration:underline;">+254 728 606 654</a> 
                          | <a href="tel:+254733523047"
                          style="color:#999999; text-decoration:underline;">+254 733 523 047</a><br>
                                  ©2023 Absolute Cabs. All Rights Reserved.
                  </td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="loading-overlay" v-if="loading">
        <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
      </div>

  </div>
</template>
<style>

</style>
<script>

export default {
  name: "BookingDetails",
  components: {},
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    rate() {
      return this.$store.state.rate 
    },
    booking() {
      // console.log(this.$store.state.booking)
      return this.$store.state.booking 
    },
  },
  mounted() {
    this.getData()
  },
  created() {
    },
  methods: {
    getData() {
      this.loading = true
      this.$store
          .dispatch('getBooking',{
              refno: this.$route.params.id
            })
          .then(() => {
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

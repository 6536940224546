import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import Vuex from "vuex";
import router from "./router";
import store from "./store";
import axios from 'axios'
// import * as VueGoogleMaps from "vue2-google-maps";
import Vue2Filters from "vue2-filters";
import moment from "moment";
import LoadScript from 'vue-plugin-load-script';
import "@/assets/style.css"
import vuetify from '@/plugins/vuetify'

Vue.config.productionTip = false

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: "AIzaSyC7ys5xYYXQtN7jV0Oz4m8cu1jct9T1jc8",
//     libraries: "places,geometry,places,visualization",
//   },
// });
Vue.prototype.moment = moment;
Vue.use(Vuex);
Vue.use(LoadScript);
Vue.use(Vue2Filters);

new Vue({
  router,
  store,
  vuetify,
  created () {
    axios.interceptors.response.use(
      response => response,
      error => {
        return Promise.reject(error)
      }
    )
  },
  render: (h) => h(App),
}).$mount("#app");

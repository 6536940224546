<template>
  <div class="home">
    <BookingDetails />
  </div>
</template>

<script>
// @ is an alias to /src
import BookingDetails from "@/components/BookingDetails.vue";

export default {
  name: "HomeView",
  components: {
    BookingDetails,
  },
};
</script>

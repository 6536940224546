<template>
	<v-app>
	  <v-app-bar app elevation="0" color="white">
	  	<!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

	      <v-toolbar-title><img src="@/assets/absolutelogotext.jpg" style="width:60% !important;text-align:center !important"/></v-toolbar-title>

	      <v-spacer></v-spacer>

	      <v-menu
	          offset-y
	          >
	          <template v-slot:activator="{ attrs, on }">
	            <v-btn
	              class="white--text ma-2 m-auto"
	              v-bind="attrs"
	              v-on="on"
	              text
	              icon
	            >
	              <v-avatar>
	                <img
	                  src="@/assets/no_user.png"
	                  alt="user"
	                  style="width:35px;height:35px"
	                >
	              </v-avatar>
	            </v-btn>
	          </template>

	          <v-list
	          style="width:130px !important;border-radius:0 !important"
	          >
	           <v-list-item
	              link 
	            >
	              <v-list-item-title style="padding:5px 5px !important;height: auto !important;margin:0 !important;font-size:15px;float:left !important">
	                <!-- <v-icon>mdi-account-check-outline</v-icon> -->
	                <span style="padding-left:3% !important">Login</span>
	              </v-list-item-title>
	            </v-list-item>

	          </v-list>
	        </v-menu>
	  	</v-app-bar>

	  <v-main>
	    <v-container fluid>
	      <router-view />
	    </v-container>
	  </v-main>
	</v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    menuVisible: false
  })
}
</script>